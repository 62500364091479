import React from 'react';
import {HeadingH2, PageContent} from "../styles/GlobalStyles";
import {Underline} from "../styles/Underline";
import ContactForm from "./contactForm";
import {KaunasMap} from "../assets/svg/icons-and-logos";
import styled from "styled-components";

const ContactConstant = () => {

    return (
            <PageContent>

                <ContactItems>
                    <HeadingH2>Kontaktai</HeadingH2>
                    <Underline fluid primary small/>

                    <ContactInfo>
                        <ul>
                            <li>Paskambinkite Mums</li>
                            <a href="tel:+37063653683">+ 370 636 52683</a>
                        </ul>

                        <ul>
                            <li>Parašykite Mums</li>
                            <a href="mailto:info@bohabo.com">info@bohabo.com</a>
                        </ul>

                        <ul>
                            <li>Mus galite rasti</li>
                            <li>Kaunas, Lietuva</li>
                        </ul>

                        <ul>
                            <li>Socialiai Tinklai</li>
                            <ContactSocialLink href="https://www.facebook.com/bohabocornhole" target="_blank" rel="noreferrer noopener nofollow">Facebook</ContactSocialLink>
                            <ContactSocialLink href="https://www.instagram.com/bohabocornhole" target="_blank" rel="noreferrer noopener nofollow">Instagram</ContactSocialLink>
                        </ul>
                    </ContactInfo>

                </ContactItems>

                <ContactFormWrapper>
                    <HeadingH2>Susisiekite su Mumis</HeadingH2>
                    <Underline fluid primary small/>

                    <ContactForm/>
                </ContactFormWrapper>


                        <LocationMapWrapper>
                             <KaunasMap/>
                        </LocationMapWrapper>

            </PageContent>
    );
};

export default ContactConstant;

const ContactInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 100%;
  margin-top: 2rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    display: grid;
    grid-template-columns: repeat(4,1fr);
     grid-template-rows: 1fr;
    grid-column-gap: 1rem;
  }
`;

const ContactItems = styled.div`
   grid-column: 1/-1;  
    text-align: center;
    align-self: center;
    justify-self: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 1/1;
    grid-column: 1/-1;
    text-align: left;
    justify-self: left;
    width: 100%;
    margin-bottom: 2rem;
  }
  
  ul {
    list-style: none;
    background: ${props => props.theme.light};
    padding: 1rem 1.5rem;
  }
  
  li:first-child {
    font-size: 14px;
    color: #999999;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }
  
  li:nth-child(2) {
    font-size: 21px;
    font-weight: 700;
  }
  
  a {
    font-size: 21px;
    font-weight: 700;
    color: ${props => props.theme.black};
  }
`;



const LocationMapWrapper = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    grid-row: 2/2;
    grid-column: 1/-1;
    justify-self: center;
    max-width: 600px;
    width: 100%;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-column: 7/13;
    transform: translate(0px, -30px);
    max-width: 100%;
  }
`;

const ContactSocialLink = styled.a`
  color: ${props => props.theme.black};
  text-decoration: none;
  margin-bottom: 0.75rem;
  font-size: 21px;
  font-weight: 700;
  margin-right: 0.6rem;
  &:hover{
    color: ${props => props.theme.primary};
    transition: 100ms ease-out;
    text-decoration: underline;
  }
`;

const ContactFormWrapper = styled.div`
  border-radius: 5px;
  grid-column: 1/-1;
  justify-self: center;
  width: 100%;
  max-width: 500px;
  text-align: center;

  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 2/2;
    grid-column: 1/6;
    max-width: 100%;
    text-align: left;
  }
`;