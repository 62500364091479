import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import {ButtonSubmit} from "../styles/Button";
import styled from "styled-components";

const ContactForm = () => {

    const [state, handleSubmit] = useForm("xknkjwdj");
    if (state.succeeded) {
        return (
            <SucceededMessage>Ačiū! Jūsų laiškas buvo išsiųstas, atsakysime artimiausiu metu.</SucceededMessage>
        )
    }

    return (
        <Form onSubmit={handleSubmit}>
            <InputField
                id="name"
                type="name"
                name="name"
                placeholder="Vardas"
            />
            <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
            />
            <InputField
                id="email"
                type="email"
                name="email"
                placeholder="El. paštas"
            />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
            />
            <TextAreaField
                id="message"
                name="message"
                placeholder="Jūsų pranešimas"
                rows="5"
            />
            <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
            />
            <ButtonSubmit big="true" type="submit" disabled={state.submitting}>
                Išsiųsti Pranešimą
            </ButtonSubmit>
        </Form>
    );
};

export default ContactForm;

const Form = styled.form`
   width: 100%;
   margin-top: 2rem;
`;

const InputField = styled.input`
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  margin-bottom: 1.25rem;
  background: ${props => props.theme.light};
  border-radius: 5px;
`;

const TextAreaField = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  margin-bottom: 2.5rem;
  background: ${props => props.theme.light};
`;

const SucceededMessage = styled.p`
  font-size: 21px;
`;